<template>
  <div class="space-y-4">
    <article>
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Create First Aid Box',
            })
          "
          icon="file-document"
          text="Create First Aid Box"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="pt-8">
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'name',
          'primaryLocationName',
          'secondaryLocationName',
          'action',
        ]"
        :sortableColumns="[
          'name',
          'primaryLocationName',
          'secondaryLocationName',
        ]"
        :data="tableData"
      >
        <template v-slot:action="scopedSlots">
          <div class="flex flex-col gap-1 w-48">
            <asom-button
              size="sm"
              outline
              text="Delete"
              variant="error"
              @click="deleteFirstAidBoxClicked(scopedSlots.rowData)"
            />
            <asom-button
              size="sm"
              outline
              text="Update"
              @click="navigateToUpdate(scopedSlots.rowData)"
            />
          </div>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
    <asom-modal
      title="Delete First Aid Box"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse">
        <div class="pl-4">
          <asom-button
            @click="onDeleteFirstAidBox"
            text="OK"
            :loading="isSubmitting"
          />
        </div>
        <div>
          <asom-button
            @click="toggleModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import {
  getListOfFirstAidBoxes,
  deleteFirstAidBox,
} from "../../../../services/inventory.service";

export default {
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      firstAidBoxList: [],
      tableData: [],
      error: null,
      showModal: false,
      selectedItem: null,
    };
  },
  watch: {
    stationId() {
      this.getPageData();
    },
  },
  mounted() {
    if (this.stationId !== null) {
      this.getPageData();
    }
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
    }),
  },
  methods: {
    navigateToUpdate(selectedItem) {
      this.$router.push({
        name: "Update First Aid Box",
        params: selectedItem,
      });
    },
    async getPageData() {
      this.isLoading = true;
      const resp = await getListOfFirstAidBoxes({
        skip: 0,
        take: 100,
        stationId: this.stationId,
        lineId: this.lineId,
      });
      if (resp.success) {
        this.firstAidBoxList = get(resp.payload, "list", []);
        this.tableData = this.firstAidBoxList;
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    deleteFirstAidBoxClicked(selectedItem) {
      this.selectedItem = selectedItem;
      this.toggleModal(true);
    },
    toggleModal(value) {
      this.showModal = value;
    },
    async onDeleteFirstAidBox() {
      this.isSubmitting = true;
      const result = await deleteFirstAidBox({
        firstAidBoxId: get(this.selectedItem, "inventoryFirstAidBoxId"),
      });
      if (result.success) {
        this.isSubmitting = false;
        this.toggleModal(false);
        this.getPageData();
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.toggleModal(false);
        this.$scrollTop();
      }
    },
  },
};
</script>
